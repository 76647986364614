@import '../core/colors.less';
@import '../core/components-defaults/pl-button.less';

[pl-button] {
    position: relative;
    display: inline-block;
    //min-width: 64px;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 0 12px;
    margin: 7px 3px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    overflow: hidden;
    // Use z index to ensure no burst overflow
    // http://stackoverflow.com/a/25206004
    z-index: 1;
    transition: border-color 50ms, background-color 50ms, color 50ms;

    &:focus {
        outline: none; // allows to adjust with the buttons shape.
        box-shadow: @gray 0px 0px 0px 3px;
        border-color: @blue-medium !important;
    }       

    &.inline {
        margin-top: 0;
        margin-bottom: 0;
    }

    &>span {
      display:inline-block;
      width: 100%;
      height: 100%;
    }

    [burst] {
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 150px;
        transform: scale(0, 0);
        opacity: 0.5;
        &.animating {
            transition: transform 550ms ease-out, opacity 450ms ease-in;
        }
        &.animate {
            opacity: 0;
            transform: scale(1, 1);
        }
    }

    // Default styles
    .btn-style(@pl-button-default-color, @pl-button-default-border, @pl-button-default-background, @pl-button-default-color-hover, @pl-button-default-border-hover, @pl-button-default-background-hover, @pl-button-default-border-hover);

    // colors / styles
    &.reverse {
        .btn-style(@pl-button-default-reverse-color, @pl-button-default-reverse-border, @pl-button-default-reverse-background, @pl-button-default-reverse-color-hover, @pl-button-default-reverse-border-hover, @pl-button-default-reverse-background-hover, @pl-button-default-reverse-border-hover);
    }

    &.link {
        .btn-style(@pl-button-link-color, @pl-button-link-border, @pl-button-link-background, @pl-button-link-color-hover, @pl-button-link-border-hover, @pl-button-link-background-hover);
        line-height: inherit;
        //padding: inherit;
        //margin: inherit;
        padding: 0;
        margin: 0;
        font-size: inherit;
        display: inline;
    }
    &.bare {
        .btn-style(@pl-button-bare-color, @pl-button-bare-border, @pl-button-bare-background, @pl-button-bare-color-hover, @pl-button-bare-border-hover, @pl-button-bare-background-hover, @pl-button-bare-color-hover);

        //font-weight: 400;
        &.active {
            color: darken(@pl-button-bare-background-hover, 20%);
            figure svg { fill: darken(@pl-button-bare-background-hover, 20%); }
        }
    }

    &.primary {
        .btn-style(@pl-button-primary-color, @pl-button-primary-border, @pl-button-primary-background, @pl-button-primary-color-hover, @pl-button-primary-border-hover, @pl-button-primary-background-hover);
    }
    &.info {
        .btn-style(@pl-button-info-color, @pl-button-info-border, @pl-button-info-background, @pl-button-info-color-hover, @pl-button-info-border-hover, @pl-button-info-background-hover);
    }
    &.warning {
        .btn-style(@pl-button-warning-color, @pl-button-warning-border, @pl-button-warning-background, @pl-button-warning-color-hover, @pl-button-warning-border-hover, @pl-button-warning-background-hover);
    }
    &.danger {
        .btn-style(@pl-button-danger-color, @pl-button-danger-border, @pl-button-danger-background, @pl-button-danger-color-hover, @pl-button-danger-border-hover, @pl-button-danger-background-hover);
    }
    &.success {
        .btn-style(@pl-button-success-color, @pl-button-success-border, @pl-button-success-background, @pl-button-success-color-hover, @pl-button-success-border-hover, @pl-button-success-background-hover);
    }

    // Must be after other styles to take precedence.
    &:disabled, .disabled {
        .btn-style(@pl-button-disabled-color, @pl-button-disabled-border, @pl-button-disabled-background, @pl-button-disabled-color-hover, @pl-button-disabled-border-hover, @pl-button-disabled-background-hover);

        &.hover, &:hover {
            .check-mark {
                border-color: @pl-button-disabled-color;
                background-color: @pl-button-disabled-background-hover;
            }
        }

        cursor: not-allowed;
        font-weight: 300;
        figure svg {
            fill: @pl-button-disabled-color;
        }
        .check-mark {
            border-color: @pl-button-disabled-color;
        }
    }

    // sizes
    &.large {
        font-size: 18px;
        line-height: 42px;
        figure {
            width: 14px;
            height: 14px;
        }
        &.pl-toggle .check-mark {
            top: 4px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            .check {
                top: 3px;
                left: 4px;
                width: 14px;
                height: 10px;
                .stem {
                    top: 0;
                    left: 5px;
                    width: 3px;
                    height: 12px;
                }
                .kick {
                    top: 4px;
                    left: 0;
                    width: 3px;
                    height: 7px;
                }
            }
        }
    }
    &.x-large {
        font-size: 20px;
        font-weight: 700;
        line-height: 58px;
    }
}
