// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './overrides.scss' as mat-overrides;
@use './components.scss' as mat-components;

@include mat.all-component-typographies();
@include mat.core();

@import './palettes.scss';
@import './themes.scss';

@include mat.all-component-themes($pl-theme);

// Existing components overrides
@include mat-overrides.tweak-menu-theme($pl-theme);
@include mat-overrides.tweak-expansion-theme($pl-theme);
@include mat-overrides.tweak-button-theme($pl-theme);
@include mat-overrides.tweak-form-field-theme($pl-theme);
@include mat-overrides.tweak-tab-group-theme($pl-theme);
@include mat-overrides.tweak-checkbox-theme($pl-theme);
@include mat-overrides.tweak-slide-toggle-theme($pl-theme);
@include mat-overrides.tweak-radio-button-theme($pl-theme);
@include mat-overrides.tweak-slider-theme($pl-theme);
@include mat-overrides.tweak-snack-bar-theme($pl-theme);
@include mat-overrides.tweak-dialog-theme($pl-theme);
@include mat-overrides.tweak-tooltip-theme($pl-theme);
@include mat-overrides.tweak-button-toggle-theme($pl-theme);
@include mat-overrides.tweak-cdk($pl-theme);
@include mat-overrides.tweak-paginator($pl-theme);
@include mat-overrides.tweak-select-color($pl-theme);
@include mat-overrides.tweak-mat-menu($pl-theme);

// Compound or custom components
@include mat-components.split-button-theme($pl-theme);
@include mat-components.slide-toggle-group-theme($pl-theme);
@include mat-components.radio-bg-button-theme($pl-theme);
@include mat-components.chips-theme($pl-theme);
@include mat-components.a-hyperlink-theme($pl-theme);
@include mat-components.library-tabs-theme($pl-theme);
@include mat-components.mat-icon-backwards();

$mat-icon-sizes: (
  '24': (
    24px,
    16px,
  ),
  '36': (
    36px,
    24px,
  ),
  '48': (
    48px,
    36px,
  ),
);

@each $name, $sizes in $mat-icon-sizes {
  .mat-icon-size-#{$name} {
    @include mat-components.mat-icon-size(nth($sizes, 1), nth($sizes, 2));
  }
}
// By default anything withing dark-theme class should inherit dark theme styles
.dark-theme {
  @include mat.all-component-colors($pl-dark-theme);

  @include mat-overrides.tweak-menu-color($pl-dark-theme);
  @include mat-overrides.tweak-select-color($pl-dark-theme);
  @include mat-overrides.tweak-button-color($pl-dark-theme);
  @include mat-overrides.tweak-form-field-color($pl-dark-theme);
  @include mat-overrides.tweak-tab-group-color($pl-dark-theme);
  @include mat-overrides.tweak-slide-toggle-color($pl-dark-theme);
  @include mat-overrides.tweak-radio-button-color($pl-dark-theme);
  @include mat-overrides.tweak-snack-bar-color($pl-dark-theme);
  @include mat-overrides.tweak-dialog-color($pl-dark-theme);
  @include mat-overrides.tweak-button-toggle-color($pl-dark-theme);

  @include mat-components.split-button-theme($pl-dark-theme);
}

// These are necessary because menu containers renders directly inside <body> element

// Uses dark theme for menu, when is-dark class is present on <mat-menu>'s backdrop
.cdk-overlay-backdrop.is-dark + * > .cdk-overlay-pane {
  @include mat-overrides.tweak-menu-color($pl-dark-theme);
}
// Uses dark theme for select, when is-dark class is present on <mat-select-panel>
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) {
  @include mat.select-color($pl-dark-theme);
  @include mat.option-color($pl-dark-theme);
  @include mat-overrides.tweak-select-color($pl-dark-theme);
}
